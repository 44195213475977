import { type SavedFilter } from '../../types/SavedFilter'

export const activeFilter: SavedFilter = {
  _id: 'active',
  isDefault: true,
  isLocked: true,
  isSystemFilter: true,
  name: 'Active',
  queryString: '',
}

export const confirmedFilter: SavedFilter = {
  _id: 'confirmed',
  isSystemFilter: true,
  name: 'Ready / Scheduled',
  queryString: 'isArchived=false&status[]=confirmed&view=confirmed&',
}
export const pickedUpFilter: SavedFilter = {
  _id: 'picked-up',
  isSystemFilter: true,
  name: 'Picked up',
  queryString: 'isArchived=false&status[]=picked-up&view=picked-up',
}
export const deliveredFilter: SavedFilter = {
  _id: 'delivered',
  isSystemFilter: true,
  name: 'Delivered',
  queryString: 'isArchived=false&status[]=delivered&view=delivered',
}
export const allConfirmedFilter: SavedFilter = {
  _id: 'all',
  icon: 'list',
  isLocked: true,
  isSystemFilter: true,
  name: 'All',
  queryString: 'view=all',
}
export const archivedConfirmedFilter: SavedFilter = {
  _id: 'archived',
  icon: 'archive',
  isSystemFilter: true,
  name: 'Archived',
  queryString: 'isArchived=true&view=archived',
}
