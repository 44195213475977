import { type PropsWithChildren, useMemo } from 'react'

import { type IconName } from '@fv/client-components'

import { yesNoOptions } from '../../components/CustomFilterForm/filterOptions'
import { type FilterConfig } from '../../components/CustomFilterForm/types'
import { type SavedFilter } from '../../types/SavedFilter'
import { useAccountFeatures } from '../auth'
import { useWorkflowFilterLists } from './hooks'
import { vendorCreatedFilter } from './shared-filters'
import { ShipmentFilterListProvider } from './ShipmentFilterListProvider'
import {
  bookedDateFilter,
  deliveryDateFilter,
  directionFilter,
  interlineFilter,
  pickupDateFilter,
  pricingMethodFilter,
  pricingTypeFilter,
  shippingModeFilter,
  useAccountFilters,
} from './useFilterConfigs'
import { useHiddenViews, useSavedViews } from './useSavedViews'

export const AuditFiltersProvider = ({ children }: PropsWithChildren) => {
  const filters = useSavedAuditListFilters()
  const filterConfig = useAuditListFilterConfig()
  return (
    <ShipmentFilterListProvider
      state={{
        listType: 'auditList',
        filters,
        filterConfig,
      }}
    >
      {children}
    </ShipmentFilterListProvider>
  )
}

function useAuditListFilterConfig(): FilterConfig[] {
  const { carrierFilter, locationFilter, tagFilter } = useAccountFilters()

  return [
    pickupDateFilter,
    deliveryDateFilter,
    bookedDateFilter,
    directionFilter,
    shippingModeFilter,
    carrierFilter,
    interlineFilter,
    {
      label: 'Invoice available',
      name: 'hasInvoice',
      options: yesNoOptions,
    },
    pricingMethodFilter,
    pricingTypeFilter,
    tagFilter,
    locationFilter,
  ]
}

const ltlAuditFilter: SavedFilter = {
  _id: 'audit-ltl',
  isSystemFilter: true,
  name: 'LTL',
  queryString: 'mode[]=ltl&view=audit-ltl',
}

const truckloadAuditFilter: SavedFilter = {
  _id: 'audit-truckload',
  isSystemFilter: true,
  name: 'Truckload',
  queryString: 'mode[]=truckload&view=audit-truckload',
}

const parcelAuditFilter: SavedFilter = {
  _id: 'audit-parcel',
  isSystemFilter: true,
  name: 'Parcel',
  queryString: 'mode[]=parcel&view=audit-parcel',
}

const allAuditFilter: SavedFilter = {
  _id: 'all',
  icon: 'list' as IconName,
  isDefault: true,
  isLocked: true,
  isSystemFilter: true,
  name: 'All',
  queryString: '',
}
const auditInvoicesFilter: SavedFilter = {
  _id: 'audit-invoices',
  isSystemFilter: true,
  name: 'Invoice available',
  queryString: 'hasInvoice=true&view=audit-invoices',
}

function useSavedAuditListFilters(): SavedFilter[] {
  const hiddenViews = useHiddenViews()
  const savedViews = useSavedViews('auditList')
  const workflowFilters = useWorkflowFilterLists([
    ltlAuditFilter,
    truckloadAuditFilter,
    parcelAuditFilter,
  ])
  const { vendorPortal } = useAccountFeatures()

  return useMemo(
    () => [
      allAuditFilter,
      ...(vendorPortal ? [vendorCreatedFilter()] : []),
      auditInvoicesFilter,
      ...workflowFilters.filter(
        f => !hiddenViews.find((id: string) => id === f._id),
      ),
      ...savedViews,
    ],
    [hiddenViews, savedViews, workflowFilters, vendorPortal],
  )
}
