import {
  type SavedFilter,
  type SavedFilterGroup,
} from '../../types/SavedFilter'

export const vendorCreatedFilter = (
  group = false,
): SavedFilter | SavedFilterGroup => ({
  _id: 'vendor-created',
  icon: 'store-alt',
  isSystemFilter: true,
  isLocked: true,
  name: 'Vendor created',
  filter: {
    isVendor: true,
  },
  queryString: '',
  ...(group ? { children: [] } : {}),
})

export const allFilter = (group = false): SavedFilterGroup | SavedFilter => ({
  _id: 'all',
  icon: 'list',
  isDefault: true,
  isLocked: true,
  isSystemFilter: true,
  name: 'All shipments',
  filter: {},
  queryString: '',
  ...(group ? { children: [] } : {}),
})
