import {
  type SavedFilter,
  type SavedFilterGroup,
} from '../../types/SavedFilter'
import { useRole } from '../auth/hooks'

export const bookingErrorsFilter: SavedFilterGroup = {
  _id: 'booking-errors',
  icon: 'exclamation-triangle',
  isLocked: true,
  isSystemFilter: true,
  name: 'Booking errors',
  filter: {
    recentType: 'booking-error',
  },
  children: [],
  queryString: '',
}

export const tlQuotingFilter: SavedFilter = {
  _id: 'tl-quoting',
  icon: 'filter',
  name: 'Quoting',
  fullLabel: 'FTL - Quoting',
  isSystemFilter: true,
  filter: { mode: ['truckload'], status: ['pending'] },
  queryString: '',
}
export const tlAwardedFilter: SavedFilter = {
  _id: 'tl-awarded',
  icon: 'filter',
  name: 'Awarded',
  fullLabel: 'FTL - Awarded',
  isSystemFilter: true,
  filter: { mode: ['truckload'], status: ['awarded'] },
  queryString: '',
}
export const useTruckloadGroupFilter = (): SavedFilterGroup => {
  const role = useRole()
  return {
    _id: 'truckload',
    icon: 'list',
    isLocked: true,
    isSystemFilter: true,
    name: 'All truckload shipments',
    filter: {
      mode: ['truckload'],
    },
    children: [
      tlQuotingFilter,
      ...(role === 'shipper'
        ? [
            tlAwardedFilter,
            {
              _id: 'tl-live',
              icon: 'filter',
              name: 'Live load',
              fullLabel: 'FTL - Live load',
              isSystemFilter: true,
              filter: { mode: ['truckload'], isLiveLoad: true },
              queryString: '',
            } satisfies SavedFilter,
          ]
        : []),
    ],
    queryString: '',
  }
}

export const ltlQuotingFilter: SavedFilter = {
  _id: 'ltl-quoting',
  icon: 'filter',
  name: 'Spot quotes - Quoting',
  fullLabel: 'LTL Spot - Quoting',
  isSystemFilter: true,
  filter: { mode: ['ltl'], status: ['pending'], isSpot: true },
  queryString: '',
}
export const ltlAwardedFilter: SavedFilter = {
  _id: 'ltl-awarded',
  icon: 'filter',
  name: 'Spot quotes - Awarded',
  fullLabel: 'LTL Spot - Awarded',
  isSystemFilter: true,
  filter: { mode: ['ltl'], status: ['awarded'], isSpot: true },
  queryString: '',
}

export const ltlRecentFilter: SavedFilter = {
  _id: 'ltl-recent',
  isSystemFilter: true,
  isLocked: true,
  name: 'Recent quotes',
  fullLabel: 'LTL - Recent quotes',
  icon: 'history',
  filter: {
    mode: ['ltl'],
    recentType: 'recent',
  },
  queryString: '',
}
export const ltlSavedFitler: SavedFilter = {
  _id: 'ltl-saved',
  isSystemFilter: true,
  isLocked: true,
  name: 'Saved quotes',
  fullLabel: 'LTL - Saved quotes',
  icon: 'star-sharp',
  filter: {
    mode: ['ltl'],
    recentType: 'saved',
  },
  queryString: '',
}

export const useLtlGroupFilter = (): SavedFilterGroup => {
  const role = useRole()
  return {
    _id: 'ltl',
    name: 'All LTL shipments',
    isSystemFilter: true,
    isLocked: true,
    filter: { mode: ['ltl'] },
    icon: 'list',
    children: [
      ltlRecentFilter,
      ...(role === 'shipper' ? [ltlSavedFitler] : []),
      ltlQuotingFilter,
      ltlAwardedFilter,
    ],
    queryString: '',
  }
}
export const parcelRecentFilter: SavedFilter = {
  _id: 'parcel-recent',
  isSystemFilter: true,
  isLocked: true,
  name: 'Recent quotes',
  fullLabel: 'Parcel - Recent quotes',
  icon: 'history',
  filter: {
    mode: ['parcel'],
    recentType: 'recent',
  },
  queryString: '',
}
export const parcelSavedFilter: SavedFilter = {
  _id: 'parcel-saved',
  isSystemFilter: true,
  isLocked: true,
  name: 'Saved quotes',
  fullLabel: 'Parcel - Saved quotes',
  icon: 'star-sharp',
  queryString: '',
  filter: {
    mode: ['parcel'],
    recentType: 'saved',
  },
}
export const useParcelGroupFilter = (): SavedFilterGroup => {
  const role = useRole()
  return {
    _id: 'parcel',
    name: 'All Parcel shipments',
    isSystemFilter: true,
    isLocked: true,
    filter: { mode: ['parcel'] },
    icon: 'list',
    queryString: '',
    children: [
      parcelRecentFilter,
      ...(role === 'shipper' ? [parcelSavedFilter] : []),
    ],
  }
}
